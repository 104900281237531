import { Fieldset, Text } from "@geist-ui/core";
import { useEffect } from "react";
import { Button } from "antd";
import * as React from "react";
import HomeLayout from "../blocks/layout/home";
import Seo from "../blocks/layout/seo";
import { Link } from "gatsby";
import VideoPlayerBox from "../blocks/videpPlayerBox";
import VideoPlayerLoop from "../blocks/videoPlayerLoop";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
// import "react-accessible-accordion/dist/fancy-example.css";
// import '../styles/reset.scss';

const accordionData = [
  {
    heading: "What is Output.ws",
    content: "This is SaaS",
  },
  {
    heading: "What is Output.ws",
    content: "This is Software",
  },

  {
    heading: "What is Output.ws",
    content: "This is Software",
  },
  {
    heading: "What is Output.ws",
    content: "This is Software",
  },
];

const addExternalScript = (url, callback) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  script.onload = callback;
  document.body.appendChild(script);
};

const IndexPage = () => {
  useEffect(() => {
    addExternalScript("/js/run-bg-scroll.js");
  }, []);
  return (
    <>
      <Seo title="Output" />
      <HomeLayout authPage={false} mainPage={true} isForCreatorsPage>
        <main data-bg-scroll>
          <div
            className="hero-section"
            data-bg-color="color-yellow"
            style={{ maxWidth: 940, margin: "0 auto" }}
          >
            <h2
              style={{
                width: 700,
                fontWeight: 400,
                fontSize: 56,
                lineHeight: "1.2em",
              }}
            >
              Get your content everywhere.
            </h2>
            <p
              style={{
                fontSize: 18,
                fontWeight: 300,
              }}
            >
              Output Is the easiest way to get your content onto Apple, Amazon,
              Roku, Pluto, Tubi, Samsung+, DailyMotion and more. Keep 100%
              ownership of your content.
            </p>
            <Link to="/signup?mode=creator">
              <Button
                type="primary"
                size="large"
                style={{ width: 380, height: 54 }}
              >
                Start now
              </Button>
            </Link>
          </div>

          {/* Features */}
          {/* <div data-bg-scroll>
            <div className="features-wrapper">
              <section
                data-bg-color="color-yellow"
                className="features-section "
              >
                <div className="content">
                  <div className="features-header">
                    <span className="subtitle">Design</span>
                    <h3>Co-create in one space</h3>
                  </div>

                  <div className="feature-section-body">
                    <div className="text-side">
                      <p>
                        Work together in real time and empower designers to
                        create in new ways. Keep workflows efficient with tools
                        that give every team visibility throughout the process.
                      </p>
                      <button className="display-button">Explore design</button>
                    </div>
                    <div className="graphic-side">
                      <img
                        src="https://images.unsplash.com/photo-1682686578615-39549501dd08?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="features-wrapper">
              <section
                data-bg-color="color-purple"
                className="features-section left-graphic"
              >
                <div className="content">
                  <div className="features-header">
                    <span className="subtitle">Design</span>
                    <h3>Co-create in one space</h3>
                  </div>

                  <div className="feature-section-body">
                    <div className="text-side">
                      <p>
                        Work together in real time and empower designers to
                        create in new ways. Keep workflows efficient with tools
                        that give every team visibility throughout the process.
                      </p>
                      <button className="display-button">Explore design</button>
                    </div>
                    <div className="graphic-side">
                      <img
                        src="https://images.unsplash.com/photo-1682686578615-39549501dd08?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="features-wrapper">
              <section
                data-bg-color="color-green"
                className="features-section "
              >
                <div className="content">
                  <div className="features-header">
                    <span className="subtitle">Design</span>
                    <h3>Co-create in one space</h3>
                  </div>

                  <div className="feature-section-body">
                    <div className="text-side">
                      <p>
                        Work together in real time and empower designers to
                        create in new ways. Keep workflows efficient with tools
                        that give every team visibility throughout the process.
                      </p>
                      <button className="display-button">Explore design</button>
                    </div>
                    <div className="graphic-side">
                      <img
                        src="https://images.unsplash.com/photo-1682686578615-39549501dd08?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="features-wrapper">
              <section
                data-bg-color="color-gray"
                className="features-section left-graphic"
              >
                <div className="content">
                  <div className="features-header">
                    <span className="subtitle">Design</span>
                    <h3>Co-create in one space</h3>
                  </div>

                  <div className="feature-section-body">
                    <div className="text-side">
                      <p>
                        Work together in real time and empower designers to
                        create in new ways. Keep workflows efficient with tools
                        that give every team visibility throughout the process.
                      </p>
                      <button className="display-button">Explore design</button>
                    </div>
                    <div className="graphic-side">
                      <img
                        src="https://images.unsplash.com/photo-1682686578615-39549501dd08?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="features-wrapper">
              <section data-bg-color="color-blue" className="features-section ">
                <div className="content">
                  <div className="features-header">
                    <span className="subtitle">Design</span>
                    <h3>Co-create in one space</h3>
                  </div>

                  <div className="feature-section-body">
                    <div className="text-side">
                      <p>
                        Work together in real time and empower designers to
                        create in new ways. Keep workflows efficient with tools
                        that give every team visibility throughout the process.
                      </p>
                      <button className="display-button">Explore design</button>
                    </div>
                    <div className="graphic-side">
                      <img
                        src="https://images.unsplash.com/photo-1682686578615-39549501dd08?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div> */}

          <div
            className="section-three"
            data-bg-color="color-purple"
            style={{ maxWidth: 940, margin: "0 auto" }}
          >
            <div className="feature-section">
              <div className="landing-feature-block">
                <span style={{ fontSize: 32, fontWeight: 400 }}>
                  1. Add your YouTube channels
                </span>
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Get your channel IDs from your YouTube dashoard and add them
                  here.
                </span>
              </div>
              <div
                style={{
                  width: "calc(100% - 200px)",
                }}
              >
                <VideoPlayerBox
                  url="https://www.youtube.com/watch?v=ASXJLo-sEHk"
                  showControls
                />
              </div>
            </div>

            <div className="feature-section">
              <div className="landing-feature-block">
                <span style={{ fontSize: 32, fontWeight: 400 }}>
                  2. Import videos
                </span>
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Select the channel and the videos you want to import to the
                  cloud for distribution.
                </span>
              </div>
              <VideoPlayerLoop url="https://output.sfo3.cdn.digitaloceanspaces.com/website/add-channel.mov" />
            </div>

            <div className="feature-section">
              <div className="landing-feature-block">
                <span style={{ fontSize: 32, fontWeight: 400 }}>
                  3. Distribute
                </span>
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Create industry standard MRSS feeds to syndicate everywhere,
                  with one click.
                </span>
              </div>
              <VideoPlayerLoop url="https://output.sfo3.cdn.digitaloceanspaces.com/website/import-videos.mov" />
            </div>

            <div className="feature-section" g>
              <div className="landing-feature-block">
                <span style={{ fontSize: 32, fontWeight: 400 }}>4. Done.</span>
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  Login to start.
                </span>
              </div>

              <VideoPlayerLoop url="https://output.sfo3.cdn.digitaloceanspaces.com/website/Output_Distributor_Demo.mp4" />
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to="/signup?mode=creator">
                <Button
                  width="200px"
                  type="primary"
                  size="large"
                  style={{ width: 380, height: 54 }}
                >
                  Start now
                </Button>
              </Link>
            </div>
          </div>

          <div
            style={{ maxWidth: 800, margin: "0 auto", padding: "40px 0"}}
            data-bg-color="color-green"
          >
            <h2
              className="display-font"
              style={{ fontSize: "4rem", marginTop: 36 }}
            >
              FAQ
            </h2>
            <Accordion allowZeroExpanded>
              {accordionData.map((item) => (
                <AccordionItem key={item.uuid}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{item.heading}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>{item.content}</AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </main>
      </HomeLayout>
    </>
  );
};

export default IndexPage;
